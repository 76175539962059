import "leaflet-sidebar-v2";
import "leaflet.locatecontrol";
import List from "list.js";

window.addEventListener("load", () => {
    setTimeout(() => {
        document.querySelectorAll(".stores-map").forEach(element => {
            let stores = [];
            let markers = {};
            let cachedStores = {};
            let map = L.map(element, {
                center: JSON.parse(element.dataset.view),
                zoom: parseFloat(element.dataset.zoom)
            });
            map.attributionControl.setPrefix('&copy; <a href="https://web.himmlisch.com.mx">Himmlisch Web</a>');
            map.zoomControl.setPosition('topright')

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            // >>>>>>>> Locate
            const locate = L.control.locate({
                position: 'topright',
                flyTo: false,
                keepCurrentZoomLevel: true,
                showPopup: false,
                strings: {
                    title: 'GPS'
                },
                locateOptions: {
                    watch: false
                }
            }).addTo(map);

            // >>>>>>>> SIDEBAR

            const $sidebar = $(`#sidebar-${element.id}`);
            const sidebar = L.control.sidebar({
                autopan: false,
                position: 'topleft',
                closeButton: true,
                container: $sidebar.get(0),
                position: 'left',
            }).addTo(map);

            $("#sidebar-store .leaflet-sidebar-back").on('click', function () {
                sidebar.open("sidebar-stores");
            });

            const openStoreInfo = (index) => {
                sidebar.open("sidebar-store");
                sidebar._tabitems[0].classList.add('active');

                const store = stores[index];

                $("#sidebar-store [data-address]").toggle(store.address != null);
                $("#sidebar-store [data-google]").toggle(store.address != null);

                $("#sidebar-store [data-title]").html(store.title);
                $("#sidebar-store [data-address]").html(store.address);
                $("#sidebar-store [data-content]").html(store.content);
                $("#sidebar-store [data-phone]").html(store.phone);
                $("#sidebar-store [data-phone]").attr("href", `tel:${store.phone}`);
                $("#sidebar-store [data-website]").html(store.website);
                $("#sidebar-store [data-website]").attr("href", store.website);
                $("#sidebar-store [data-google]").attr("href", store.dontBeEvil.url);
                $("#sidebar-store [data-google-label]").html(store.dontBeEvil.label);

            };

            if (element.dataset.open) {
                sidebar.open('sidebar-stores');
                sidebar._tabitems[0].classList.add('active');
            }

            const list = new List(`sidebar-content-${element.id}`, {
                valueNames: ['id', 'store-title', 'store-address', 'store-category'],
                item: `
                <li class="sidebar-store-item">
                    <h3 class="store-title"></h3>
                    <p class="store-info store-address"></p>
                    <p class="store-info store-category"></p>
                    <div>
                        <a href="" class="store-button" target="_blank">
                            Visit website
                        </a>
                    </div>
                </li>
                `
            });

            // >>>>>>>>>> GEOLOCATION

            let filterCircle = null;

            const $select = $sidebar.find('.sidebar-stores-filter');

            const filterMarkers = async (e) => {
                let latitude = e.latitude;
                let longitude = e.longitude;
                const radius = $select.val() == 'all' ? -1 : parseInt($select.val());

                if (cachedStores[`${radius}`] == null) {
                    const data = await fetch(`${window.location.origin}/task:requestStores`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            radius: radius,
                            coordinates: [latitude, longitude]
                        })
                    });
                    if (data.status != 200) {
                        console.error('Couldn\'t load stores data')
                        return;
                    }

                    stores = await data.json();
                    cachedStores[`${radius}`] = stores;
                } else {
                    stores = cachedStores[`${radius}`];
                }


                let newMarkers = [];
                stores.forEach(store => {
                    if (markers[store.id] == null) {
                        const lat = store.lat;
                        const lng = store.lng;
                        const categories = store.categories;
                        const markerOptions = {};

                        if (store.icon) {
                            markerOptions.icon = new L.icon({
                                iconUrl: store.icon,
                                iconSize: store.size ?? null
                            });
                        }

                        const marker = L.marker([lat, lng], markerOptions).addTo(map);

                        const title = store.title;
                        const address = store.address;
                        const dontBeEvil = store.dontBeEvil;

                        let popUp = `<p><strong>${title}</strong></p>`;
                        if (address) {
                            popUp += `<p>${address}</p><a href="${dontBeEvil.url}" target="_blank">${dontBeEvil.label}</a>`;
                        }
                        if (categories) {
                            popUp += "<ul class='stores-categories'>";
                            if (Array.isArray(categories)) {
                                categories.forEach(category => {
                                    popUp += `<li class="stores-category">${category}</li>`;
                                });
                            } else {
                                popUp += `<li class="stores-category">${categories}</li>`;
                            }
                            popUp += "</ul>";
                        }
                        const addedList = list.add({
                            "id": store.id,
                            "store-title": title,
                            "store-address": address,
                            "store-category": Array.isArray(categories) ? categories.join(', ') : categories,
                        });
                        const $addedList = $(addedList[0].elm);

                        $addedList.find('.store-button').attr('href', store.website);

                        $addedList.on('click', () => {
                            openStoreInfo(list.items.indexOf(addedList[0]));
                            marker.openPopup();
                            map.panTo(marker.getLatLng());
                        });

                        marker.bindPopup(popUp);
                        markers[store.id] = marker;
                    }
                    newMarkers[store.id] = markers[store.id];
                });

                Object.keys(markers).forEach((key) => {
                    if (newMarkers[key] == null) {
                        markers[key].remove();
                        delete markers[key];
                        list.remove('id', key);
                    }
                });

                list.sort('store-title');

                if (filterCircle !== null) {
                    if ($select.val() == 'all') {
                        filterCircle.setStyle({
                            opacity: 0,
                            fillOpacity: 0,
                        });
                        filterCircle.redraw();
                        return;
                    } else {
                        filterCircle.setStyle({
                            opacity: 0.2,
                            fillOpacity: 0.01,
                        });
                    }
                }
                // latitude = 48.8534951; longitude = 2.3483915; // * Debug

                if (filterCircle == null) {
                    filterCircle = L.circle([latitude, longitude], {
                        color: 'var(--brand)',
                        opacity: 0.2,
                        fillColor: 'var(--brand)',
                        fillOpacity: 0.01,
                        radius: radius * 1000
                    }).addTo(map);
                } else {
                    filterCircle.setRadius(radius * 1000);
                    filterCircle.redraw();
                }
            };


            // $select.children().eq(1).prop('selected', true);
            $select.on('change', () => {
                locate.stop();
                locate.start();
            });

            map.on('locationfound', filterMarkers);
            locate.start();

            element.store_map = map;
        });
    }, 1000);
});